import './App.css';
import Navbar from './components/nav/Navbar';
import Calculation from './components/Calculation';
import { Div, Container } from "atomize";

import 'rheostat/initialize';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'rheostat/lib/themes/DefaultTheme';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  rheostat: {
    ...DefaultTheme.rheostat,
    color: {
      ...DefaultTheme.rheostat.color,
      progressBar: 'blue',
      // progressBar: 'linear-gradient(135deg, rgba(117,78,238,1) 25%, rgba(110,230,191,1) 100%)',
    },
  },
});

function App() {
  return (
    <div className="App">
      <Container>
        <Navbar />
        <Calculation />
      </Container>
    </div>
  );
}

export default App;
