import React from "react";
import { Div, Row, Col } from "atomize";

// NPM Modules
import { StyleSheet, css } from "aphrodite";

import logo from '../../futlogo.png';

const Button = ({
}) => {
  return (
    <Row className={css(styles.row)}>
      <a href="https://www.quantfive.org" target="_blank" className={css(styles.logo)}>
        <img className={css(styles.futlogo)} src={logo} /> <span className={css(styles.fut)}>FUT</span> <span className={css(styles.insights)}>Insights</span> <span className={css(styles.byQ5)}>by Q5</span>
      </a>
      <a href="https://twitter.com/patricklu10" target="_blank" className={css(styles.twitter)}>
        <i class="fab fa-twitter"></i>
      </a>
    </Row>
  );
};

const styles = StyleSheet.create({
  imgSize: {
    height: 20,
  },
  logo: {
    fontSize: 20,
    padding: 16,
    color: '#111',
    fontFamily: "'Raleway', sans-serif",
    cursor: 'pointer',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  futlogo: {
    height: 35,
  },
  fut: {
    fontWeight: 700,
  },
  insights: {
    fontWeight: 500,
  },
  byQ5: {
    opacity: .5,
    fontWeight: 300,
  },
  twitter: {
    fontSize: 30,
    position: 'absolute',
    top: 25,
    // transform: 'translate(-50%)',
    right: 0,
  },
  row: {
    position: 'relative',
  },
});

export default Button;
