import React, { useEffect, useState } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { Div, Row, Col, Text, Icon, Input } from "atomize";
import numeral from "numeral";
import Select from "react-select";
import Rheostat from "rheostat";

// Components
import PrimeIconPack from "../prime.png";
import MomentsIconPack from "../moments.png";
import MidIconPack from "../mid.png";
import PrimeGullit from "../prime-gullit.png";
import Vieira from "../vieira.png";
import Maldini from "../maldini.png";
import Eusebio from "../eusebio.png";
import PartyBag from "../partybag.png";
import EightyFive from "../85.png";
import EightyFiveGraph from "../85+distribution.png";
import IconAttackers from "../icon-attackers.png";
import dots from "../dots.png";
import {
  expectedValue,
  calculateEvalue,
  median,
  getMinMaxPrices,
  getPlayerPrices,
  numMoreThanCost,
  probabilityMoreThanCost,
  probabilityWithinRange,
  selectedPlayersPercentage,
  stdv,
} from "./calculate";

const customStyles = {
  contaner: (provided, state) => ({
    ...provided,
    flex: 1,
  }),
  control: (provided, state) => ({
    ...provided,
    // border: "2px solid #D7D7D7",
    // borderLeftColor: 'rgba(117, 78, 238, 1)',
    // borderTopColor: 'rgba(117, 78, 238, 1)',
    // borderRightColor: 'rgba(110, 230, 191, 1)',
    // borderBottomColor: 'rgba(110, 230, 191, 1)',
    cursor: "pointer",
    "&:hover": {
      // borderColor: "#D7D7D7",
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999999999,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999999999,
  }),
};

const PackCard = ({ title, src }) => {
  return (
    <div className={css(styles.card)}>
      <h2 className={css(styles.h2)}>{title}</h2>
      <img src={src} className={css(styles.packImage)} />
    </div>
  );
};

const StatCard = ({ title, value, description }) => {
  return (
    <div className={css(styles.stat)}>
      <div className={css(styles.statTitle)}>{title}</div>
      <div className={css(styles.statValue)}>{value}</div>
      <p className={css(styles.statDescription)}>{description}</p>
    </div>
  );
};

const packs = [
  {
    title: "Attacker Icon Upgrade",
    src: IconAttackers,
    id: "icon-attackers",
    baseCost: 600000,
  },
  {
    title: "85+ x5 Upgrade",
    src: EightyFive,
    id: "5x85",
    baseCost: 162250,
    ignoreSlider: true,
    img: EightyFiveGraph,
    extra_stats: [
      {
        value: "87%",
        title: "Probability > 120k",
        description: "Pretty likely! You should get something > 120k.",
      },
      {
        value: "18.4%",
        title: "Probability > 200k",
        description:
          "Not as bad as I thought! Close to a 20% chance of getting a pack > 200k.",
      },
      {
        value: "5.85%",
        title: "Probability > 300k",
        description: "There's always hope.",
      },
    ],
  },
  {
    title: "FUT Birthday Party Bag",
    src: PartyBag,
    id: "birthday-partybag",
    baseCost: 100000,
  },
  {
    title: "Prime or Moments Icon Pack",
    src: MomentsIconPack,
    id: "prime-or-moments",
    baseCost: 600000,
  },
  {
    title: "91+ Prime Icon Pack",
    src: PrimeGullit,
    id: "91+",
    baseCost: 600000,
  },
  {
    title: "Prime Icon Pack",
    src: PrimeIconPack,
    id: "prime",
    baseCost: 400000,
  },
  {
    title: "Mid or Prime Icon Pack",
    src: Vieira,
    id: "mid-or-prime",
    baseCost: 600000,
  },
  {
    title: "Mid Icon Pack",
    src: MidIconPack,
    id: "mid",
    baseCost: 600000,
  },
  {
    title: "Base or Mid Icon Pack",
    src: Maldini,
    id: "base-or-mid",
    baseCost: 600000,
  },
  {
    title: "Base Icon Pack",
    src: Eusebio,
    id: "base",
    baseCost: 600000,
  },
];

const log10 = {
  getPosition: (value, min, max) => {
    const minv = Math.log(min);
    const maxv = Math.log(max);

    const scale = (maxv - minv) / 100;

    return (Math.log(value) - minv) / scale;
  },

  getValue: (positionPercent, min, max) => {
    const minv = Math.log(min);
    const maxv = Math.log(max);

    if (positionPercent === 0) {
      return min;
    }

    if (positionPercent === 100) {
      return max;
    }

    // calculate adjustment factor
    const scale = (maxv - minv) / 100;

    return Math.floor(Math.exp(minv + scale * positionPercent)) || 0;
  },
};

const ProgressBar = ({ style }) => {
  let constStyle = {
    ...style,
  };
  return (
    <div className={css(styles.barContainer)}>
      <div style={constStyle} className={css(styles.bar)} />
    </div>
  );
};

const PitComponent = ({ style, children }) => {
  let constStyle = {
    ...style,
    background: "rgb(176, 176, 176)",
  };

  let currentValue = children; //(children * 100 / this.props.maxPriceValue);
  let height = 4.5 + currentValue;

  if (currentValue > 40 && currentValue < 60) {
    height = 90 + currentValue - 20;
  }

  if (currentValue > 70) {
    height -= (currentValue - 70) * 2.5;
  }

  constStyle.height = height;
  constStyle.top = -height;

  // if (this.state.left > children * 100 / this.props.maxPriceValue) {
  //   constStyle.background = '#DDDDDD';
  // }

  // if (this.state.right < children * 100 / this.props.maxPriceValue) {
  //   constStyle.background = '#DDDDDD';
  // }

  return (
    <div
      style={constStyle}
      className={css(styles.pits)}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

const Calculation = ({}) => {
  const [step, setStep] = useState(0);
  const [whichPack, setWhichPack] = useState(null);
  const [packIndex, setPackIndex] = useState(null);
  const [baseCost, setBaseCost] = useState(600000);
  const [countMoreThanBaseCost, setCountMoreThanBaseCost] = useState(0);
  const [playerOptions, setPlayerOptions] = useState([]);
  const [chosenPlayersPercentage, setChosenPlayersPercentage] = useState(0);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [pitPoints, setPitPoints] = useState([]);
  const [leftPrice, setLeftPrice] = useState(0);
  const [rightPrice, setRightPrice] = useState(0);

  const stats = [
    {
      title: () => {
        return `Chosen Player Percentage`;
      },
      description: () => {
        return `The percentage chance of getting a player you want.`;
      },
      value: () => chosenPlayersPercentage + "%",
    },
    {
      title: () => {
        return `Price Range Probability`;
      },
      description: () => {
        return `The percentage chance of getting a player within ${numeral(
          leftPrice
        ).format("0[.]0a")} - ${numeral(rightPrice).format(
          "0[.]0a"
        )}. There are ${
          probabilityWithinRange(whichPack, leftPrice, rightPrice).count
        } / ${playerOptions.length} in this range.`;
      },
      value: () =>
        probabilityWithinRange(whichPack, leftPrice, rightPrice).prob + "%",
    },
    {
      title: () => {
        return "Expected Value";
      },
      description: () =>
        "The 'average' value of the pack. This value is a little deceiving though as it's very top weighted (some players are 15M).",
      value: () => expectedValue(whichPack),
    },
    {
      title: () => {
        return `Probability > ${numeral(packs[packIndex].baseCost).format(
          "0[.]0a"
        )}`;
      },
      description: () =>
        `There are ${countMoreThanBaseCost} / ${
          playerOptions.length
        } players that cost more than ${numeral(
          packs[packIndex].baseCost
        ).format("0[.]0a")}.`,
      value: () =>
        probabilityMoreThanCost(whichPack, packs[packIndex].baseCost) + "%",
    },
    // {
    //   title: () => {
    //     return `Median`
    //   },
    //   description: () => {
    //     return `This is a measure that is resistant to outliers. What the 'average' user should pack.`
    //   },
    //   value: () => median(whichPack)
    // },
    {
      title: () => {
        return `Standard Deviation`;
      },
      description: () => {
        return `In a normal distribution, 70% of player prices will be within ${expectedValue(
          whichPack
        )} +- ${stdv(whichPack)}.`;
      },
      value: () => stdv(whichPack),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (step > 0) {
      setCountMoreThanBaseCost(
        numMoreThanCost(whichPack, packs[packIndex].baseCost)
      );

      let prices = { ...getPlayerPrices(whichPack) };
      let players = Object.keys(prices);
      let options = [];

      let pitPoints = [];
      let minMax = getMinMaxPrices(whichPack);
      setMaxPrice(minMax.max);
      setMinPrice(minMax.min);
      setLeftPrice(minMax.min);
      setRightPrice(minMax.max);

      let totalPrices = 0;

      for (let i = 0; i < players.length; i++) {
        let player = players[i];
        totalPrices += prices[player].price;
      }

      for (let i = 0; i < players.length; i++) {
        let player = players[i];

        prices[player].value = player;
        prices[player].label = player;
        options.push(prices[player]);

        pitPoints.push(
          log10.getValue(
            prices[player].price / totalPrices,
            minMax.min,
            minMax.max
          )
        );
      }
      setPitPoints(pitPoints);
      setPlayerOptions(options);
    } else {
      setCountMoreThanBaseCost(0);
    }
  }, [step]);

  useEffect(() => {
    if (whichPack === "birthday-partybag") {
      setBaseCost(100000);
    }
  }, [whichPack]);

  useEffect(() => {
    setChosenPlayersPercentage(
      selectedPlayersPercentage(whichPack, selectedPlayers)
    );
  }, [selectedPlayers]);

  const packPicked = (packId, packIndex) => {
    setStep(1);
    setWhichPack(packId);
    setPackIndex(packIndex);
  };

  const sliderUpdated = (comp) => {
    let leftPrice = Math.round(comp.values[0] / 1000) * 1000;
    let rightPrice = Math.round(comp.values[1] / 1000) * 1000;
    setLeftPrice(leftPrice);
    setRightPrice(rightPrice);
    // this.setState({
    //   left: comp.values[0] * 100 / this.props.maxPriceValue,
    //   right: comp.values[1] * 100 / this.props.maxPriceValue,
    // });
  };

  const changeLeftPrice = (e) => {
    let number = parseInt(e.target.value.split(",").join(""));
    let price = number;
    if (number > 100000) {
      price = Math.round(number / 1000) * 1000;
    }
    setLeftPrice(price, 10);
  };

  const changeRightPrice = (e) => {
    let number = parseInt(e.target.value.split(",").join(""));
    let price = number;
    if (number > 100000) {
      price = Math.round(number / 1000) * 1000;
    }
    setRightPrice(parseInt(price, 10));
  };

  return (
    <div className={css(styles.container)}>
      {/* <img src={dots} className={css(styles.dots)} /> */}
      {step === 0 ? (
        <div className={css(styles.step0)}>
          <Text textSize={"display3"}>FIFA 21</Text>
          <br />
          <Text textSize={"display1"}>
            Select a Pack to Calculate Probabilities
          </Text>
          <div className={css(styles.stepSection)}>
            {packs.map((pack, index) => {
              return (
                <div
                  className={css(styles.packContainer)}
                  onClick={() => packPicked(pack.id, index)}
                >
                  <PackCard title={pack.title} index={index} src={pack.src} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={css(styles.step1)}>
          <div className={css(styles.titleSection)}>
            <i
              className={"fas fa-long-arrow-left " + css(styles.back)}
              onClick={() => setStep(0)}
            ></i>
            <Text textSize={"display1"}>{packs[packIndex].title}</Text>
          </div>
          <div className={css(styles.userInputSection)}>
            <div className={css(styles.searchSection)}>
              <Select
                options={playerOptions}
                isMulti={true}
                styles={customStyles}
                placeholder={"Select Players You Want To Pack"}
                onChange={(players) => {
                  setSelectedPlayers(players);
                }}
              />
            </div>
            {packs[packIndex].ignoreSlider ? null : (
              <div className={css(styles.rangeSection)}>
                <Rheostat
                  min={minPrice}
                  max={maxPrice}
                  // pitComponent={PitComponent}
                  // pitPoints={pitPoints}
                  onValuesUpdated={sliderUpdated}
                  progressBar={ProgressBar}
                  values={[leftPrice, rightPrice]}
                  algorithm={log10}
                />
                <div className={css(styles.ranges)}>
                  <div className={css(styles.legends)}>
                    <input
                      className={css(styles.input)}
                      value={numeral(leftPrice).format("0,0")}
                      onChange={changeLeftPrice}
                    />
                    <div className={css(styles.label)}>min price</div>
                  </div>
                  <div className={css(styles.dash)}>-</div>
                  <div className={css(styles.legends)}>
                    <input
                      className={css(styles.input)}
                      value={numeral(rightPrice).format("0,0")}
                      onChange={changeRightPrice}
                    />
                    {/* {numeral(rightPrice).format("($0[.]00a)") + "+"} */}
                    <div className={css(styles.label)}>max price</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={css(styles.statsSection)}>
            {stats.map((stat, index) => {
              if (packs[packIndex].ignoreSlider && index === 1) {
                return null;
              }
              return (
                <div className={css(styles.statContainer)}>
                  <StatCard
                    title={stat.title()}
                    value={stat.value()}
                    description={stat.description()}
                    key={`stat_card_${index}`}
                  />
                </div>
              );
            })}
            {packs[packIndex].extra_stats
              ? packs[packIndex].extra_stats.map((stat, index) => {
                  return (
                    <div className={css(styles.statContainer)}>
                      <StatCard
                        title={stat.title}
                        value={stat.value}
                        description={stat.description}
                        key={`stat_card_2_${index}`}
                      />
                    </div>
                  );
                })
              : null}
          </div>
          {packs[packIndex].img ? (
            <img className={css(styles.graph)} src={packs[packIndex].img} />
          ) : null}
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  imgSize: {
    height: 20,
  },
  container: {
    position: "relative",
    paddingTop: 60,
    paddingBottom: 36,

    "@media only screen and (max-width: 767px)": {
      paddingTop: 25,
    },
  },
  dots: {
    position: "absolute",
    width: "100%",
    left: 0,
  },
  logo: {
    fontSize: 20,
    padding: 16,
    fontFamily: "'Raleway', sans-serif",
    cursor: "pointer",
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  futlogo: {
    height: 35,
  },
  fut: {
    fontWeight: 700,
  },
  insights: {
    fontWeight: 500,
  },
  titleSection: {
    position: "relative",
  },
  byQ5: {
    opacity: 0.5,
    fontWeight: 300,
  },
  back: {
    cursor: "pointer",
    // marginRight: 'auto',
    // position: 'absolute',
    // left: 16,
    fontSize: 20,
    // top: '50%',
    // transform: 'translateY(-50%)',
  },
  graph: {
    width: "100%",
  },
  dash: {
    fontSize: 20,
    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  card: {
    border: "2px solid #ddd",
    borderRadius: 12,
    padding: 32,
    cursor: "pointer",
    background: "#fff",
    // borderLeftColor: '#754EEE',
    // borderTopColor: '#754EEE',
    // borderRightColor: '#6EE6BF',
    // borderBottomColor: '#6EE6BF',
    ":hover": {
      boxShadow:
        "0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%)",
      border: "8px solid",
      transform: "scale(1.05)",
      // background: 'linear-gradient(135deg, rgba(117,78,238,1) 25%, rgba(110,230,191,1) 100%)',
      transition: ".2s ease-in-out",
      borderLeftColor: "rgba(117, 78, 238, 1)",
      borderTopColor: "rgba(117, 78, 238, 1)",
      borderRightColor: "rgba(110, 230, 191, 1)",
      borderBottomColor: "rgba(110, 230, 191, 1)",
      // color: '#fff',
    },
  },
  packImage: {
    // width: '100%',
    height: 300,
    marginTop: 16,
    maxWidth: "100%",
    objectFit: "contain",
  },
  h2: {
    fontSize: 16,
    // letterSpacing: .7,
  },
  step0: {},
  stepSection: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 45,
    "@media only screen and (max-width: 767px)": {
      paddingTop: 15,
    },
  },
  h1: {
    fontSize: 33,
  },
  packContainer: {
    width: "33%",
    padding: 16,
    boxSizing: "border-box",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  statsSection: {
    display: "flex",
    flexWrap: "wrap",
  },
  ranges: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,

    "@media only screen and (max-width: 767px)": {
      "flex-wrap": "wrap",
    },
  },
  legends: {
    paddingRight: 16,
    paddingLeft: 16,
    position: "relative",

    "@media only screen and (max-width: 767px)": {
      marginTop: 8,
    },
  },
  label: {
    position: "absolute",
    top: 8,
    left: 32,
    fontWeight: 500,
  },
  userInputSection: {
    paddingTop: 16,
    width: "50%",
    margin: "0 auto",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
      paddingTop: 0,
    },
  },
  searchSection: {
    padding: 16,
    flex: 1,
  },
  rangeSection: {
    padding: 16,
    flex: 1,
    paddingTop: 22,
  },
  stat: {
    padding: 32,
    boxSizing: "border-box",
    border: "2px solid #ddd",
    paddingTop: 8,
    borderRadius: 12,
    height: 235,
    background: "#fff",
    // transform: 'scale(1.05)',
    // borderLeftColor: 'rgba(117, 78, 238, 1)',
    // borderTopColor: 'rgba(117, 78, 238, 1)',
    // borderRightColor: 'rgba(110, 230, 191, 1)',
    // borderBottomColor: 'rgba(110, 230, 191, 1)',
  },
  statContainer: {
    width: "33%",
    padding: 16,
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  statContainerChosen: {
    margin: "0 auto",
  },
  barContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: -3,
  },
  bar: {
    background:
      "linear-gradient(135deg, rgba(117,78,238,1) 15%, rgba(110,230,191,1) 100%)",
    height: "100%",
    position: "absolute",
  },
  input: {
    borderRadius: 8,
    border: "2px solid #ddd",
    padding: 16,
    paddingTop: 32,
    fontSize: 20,
  },
  statTitle: {
    fontSize: "1.1rem",
    marginTop: 8,
  },
  statValue: {
    fontSize: "2rem",
    padding: 16,
    fontWeight: 700,
  },
  statDescription: {
    opacity: 0.7,
  },
});

export default Calculation;
