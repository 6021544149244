import PrimePrices from '../prices/prime_prices.json';
import MomentsPrices from '../prices/moments_prices.json';
import MidPrices from '../prices/mid_prices.json';
import BasePrices from '../prices/base_prices.json';
import numeral from 'numeral';

import RuleBreakers from '../prices/partybag/birthday/Rule-Breaker-05-04-2021.json';
import UELRTTF from '../prices/partybag/birthday/Europa-Live-05-04-2021.json';
import UCLRTTF from '../prices/partybag/birthday/UCL-Live-05-04-2021.json';
import RecordBreakers from '../prices/partybag/birthday/Record-Breaker-05-04-2021.json';
import TOTGS from '../prices/partybag/birthday/UCL-TOTGS-05-04-2021.json';
import Headliners from '../prices/partybag/birthday/Headliners-05-04-2021.json';
import WhatIf from '../prices/partybag/birthday/What-If-05-04-2021.json';
import WhatIfUpgrade from '../prices/partybag/birthday/What-Ifupgrade-05-04-2021.json';
import FUTBirthday from '../prices/partybag/birthday/FUT-Birthday-05-04-2021.json';
import FutureStars from '../prices/partybag/birthday/Future-Stars-05-04-2021.json';
import EightyFivePrices from '../prices/85_4_9_21.json';
import { Chance } from 'chance';
// import WeightedList from './js-weighted-list';

const players = Object.keys(PrimePrices);
const convert_str_to_number = (x) => {
  let total_stars = 0
  let num_map = {'K':1000, 'M':1000000, 'B':1000000000}
  if (x.toLocaleUpperCase().includes('K')) {
    return x.substring(0, x.length - 1) * num_map['K']
  } else if (x.toLocaleUpperCase().includes('M')) {
    return x.substring(0, x.length - 1) * num_map['M']
  }
}

const calculateStdv = (prices, mean) => {
  let sumOfSquares = 0;
  let players = Object.keys(prices);
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    try {
      sumOfSquares += (prices[player].price - convert_str_to_number(mean)) ** 2;
    } catch(e) {
      debugger
    }
  }

  let variance = Math.sqrt(sumOfSquares / players.length);

  return numeral(variance).format('0[.]0a');
}

const calculateProbability = (price_dict) => {
  let probabilities = {}
  let total = 0;
  let total_weight = 0;
  let player_weights = {};
  let players = Object.keys(price_dict);
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    try {
      total += price_dict[player].price
    } catch(e) {
      debugger
    }
  }
  
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    try {
      if (price_dict[player].price === 0) {
        price_dict[player].price = convert_str_to_number('15M')
      }  
    } catch(e) {
      debugger
    }
    let player_weight = total / price_dict[player].price
    total_weight += player_weight
    player_weights[player] = player_weight
  }

  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let player_probability = player_weights[player] / total_weight
    probabilities[player] = player_probability * 100
  }
  
  return probabilities
}

export const calculateEvalue = (distribution, prices, prices_2=null) => {
  let evalue = 0
  let players = Object.keys(distribution);
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let price = 0
    if (prices_2 && prices_2[player]) {
      price = prices_2[player].price
    } else {
      price = prices[player].price
    }
    evalue += distribution[player] / 100 * price
  }
  return evalue
}

const calculate_two_pack_probability = (pack_one, pack_two, suffix_one, suffix_two) => {
  let probabilities = {}
  let total = 0
  let total_weight = 0
  let player_weights = {}
  let players = Object.keys(pack_one);
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let pack_one_prob = pack_one[player].price
    if (pack_one_prob === 0) {
      pack_one_prob = convert_str_to_number('15M')
    }
    let pack_two_prob = pack_two[player].price
    if (pack_two_prob === 0) {
      pack_two_prob = convert_str_to_number('15M')
    }
    total += pack_one_prob + pack_two_prob;
  }

  for (let i = 0; i < players.length; i++) {
    let player = players[i];

    let pack_one_prob = pack_one[player].price
    if (pack_one_prob === 0) {
      pack_one_prob = convert_str_to_number('15M')
    }
    let pack_two_prob = pack_two[player].price
    if (pack_two_prob === 0) {
      pack_two_prob = convert_str_to_number('15M')
    }

    let player_weight_one = total / pack_one_prob
    let player_weight_two = total / pack_two_prob
    total_weight += player_weight_one + player_weight_two
    player_weights[player + suffix_one] = player_weight_one
    player_weights[player + suffix_two] = player_weight_two

  }
  
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let player_probability = player_weights[player] / total_weight
    probabilities[player] = player_probability * 100
  }

  return probabilities
}

const countNumMoreThanCost = (prices, cost) => {
  let players = Object.keys(prices);
  let count = 0;
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    if (prices[player].price === 0) {
      prices[player].price = convert_str_to_number('15M')
    }
    if (prices[player].price >= cost) {
      count += 1;
    }
  }

  return count
}
const chance = new Chance();

const sampleWithoutReplacement = (numSamples, items, dist) => {
  let chosen = [];
  let itemsTemp = [...items];
  let distTemp = [...dist];
  for (let i = 0; i < numSamples; i++) {
    let chosenItem = chance.weighted(itemsTemp, distTemp);
    chosen.push(chosenItem);

    let index = itemsTemp.indexOf(chosenItem);
    itemsTemp.splice(index, 1);
    distTemp.splice(index, 1);
  }

  return chosen;
}

const calculateMedian = arr => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const median = (type) => {
  let prices = getPlayerPrices(type);
  let players = Object.keys(prices);
  let distribution = calculateProbability(prices);
  let playerPrices = [];
  let probs = [];
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    playerPrices.push(prices[player]);
    probs.push(distribution[player]);
  }
  let trials = 10000;
  let allSamples = [];
  for (let i = 0; i < trials; i++) {
    let sample = sampleWithoutReplacement(1, playerPrices, probs);

    allSamples.push(sample[0].price);
  }

  return numeral(calculateMedian(allSamples)).format('0[.]0a');
}

const calculateProbabilityMoreThanCost = (prices, cost, distribution, type='') => {
  let players = Object.keys(prices);
  let prob = 0;
  if (type === '5x85') {
    // let playerPrices = [];
    // let count = 10000;
    // let totalMore = 0;
    // let probs = [];
    // for (let i = 0; i < players.length; i++) {
    //   let player = players[i];
    //   playerPrices.push(prices[player]);
    //   probs.push(distribution[player]);
    // }
    // for (let i = 0; i < count; i++) {
    //   // let wl = new WeightedList(data);
    //   // let result = wl.pop(5);
    //   let result = sampleWithoutReplacement(5, playerPrices, probs);

    //   let price = 0;
    //   for (let j = 0; j < result.length; j++) {
    //     price += result[j].price;
    //   }

    //   if (price > cost) {
    //     totalMore += 1;
    //   }
    // }

    // prob = totalMore / count * 100;
    return '23.79'
  } else {
    for (let i = 0; i < players.length; i++) {
      let player = players[i];
      if (prices[player].price === 0) {
        prices[player].price = convert_str_to_number('15M')
      }
      if (prices[player].price >= cost) {
        prob += distribution[player];
      }
    }  
  }

  return numeral(prob).format('0[.]0')
}

const combineTwoPrices = (priceOne, priceTwo, suffixOne, suffixTwo) => {
  let prices = {}
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    prices[player + suffixOne] = priceOne[player];
    if (priceOne[player] === 0) {
      prices[player + suffixOne] = convert_str_to_number('15M')
    }
    prices[player + suffixTwo] = priceTwo[player];
    if (priceTwo[player] === 0) {
      prices[player + suffixTwo] = convert_str_to_number('15M')
    }
  }
  return prices;
}

const combineMultiPrices = (prices, suffixes) => {
  let combinedPrices = {};
  for (let i = 0; i < prices.length; i++) {
    let curPrices = prices[i];
    let curSuffix = suffixes[i];

    for (let j = 0; j < players.length; j++) {
      let currentPlayer = players[j];
      combinedPrices[currentPlayer + curSuffix] = curPrices[currentPlayer];
      if (curPrices[currentPlayer] === 0) {
        combinedPrices[currentPlayer + curSuffix] = convert_str_to_number('15M')
      }
    }
  }

  return combinedPrices;
}

export const calculatePrimeProbabilities = () => {
  return calculateProbability(PrimePrices);
}

export const probabilityMoreThanCost = (type, baseCost) => {
  let prices = getPlayerPrices(type);
  return calculateProbabilityMoreThanCost(prices, baseCost, calculateProbability(prices), type)
}

export const stdv = (type) => {
  let prices = getPlayerPrices(type);
  if (type === '5x85') {
    return '95.85k'
  }
  return calculateStdv(prices, expectedValue(type))
}

export const numMoreThanCost = (type, baseCost) => {
  let prices = null;
  prices = getPlayerPrices(type);
  return countNumMoreThanCost(prices, baseCost)
}

export const expectedValue = (type) => {
  let probabilities = null;
  let prices = {}
  let evalue = 0;
  prices = getPlayerPrices(type);
  probabilities = calculateProbability(prices);
  evalue = calculateEvalue(probabilities, prices);

  if (type === '5x85') {
    evalue *= 5;
  }
  return numeral(evalue).format('0[.]0a');
}

export const calculatePrimeEvalue = () => {
  let primeProbabilities = calculateProbability(PrimePrices);
  let evalue = calculateEvalue(primeProbabilities, PrimePrices);
  return numeral(evalue).format('0[.]0a');
}

const ATTACKER_POS = ['ST', 'LW', 'LF', 'CF', 'RW', 'RF'];
const getAttackers = (prices) => {
  let dist = {};
  let players = Object.keys(prices);
  for (let i = 0; i < players.length; i++) {
    let curPlayer = players[i];
    if (ATTACKER_POS.includes(prices[curPlayer].position)) {
      dist[curPlayer] = prices[curPlayer];
    }
  }

  return dist;
}

export const getPlayerPrices = (type) => {
  let prices = {}
  switch(type) {
    case 'icon-attackers':
      let combinedPrices = combineMultiPrices([MidPrices, PrimePrices, MomentsPrices], [' Mid', ' Prime', ' Moments'])
      let combinedAttackers = getAttackers(combinedPrices);
      prices = {
        ...combinedAttackers,
      }
      break;
    case '5x85':
      prices = EightyFivePrices;
      break;
    case 'birthday-partybag':
      let priceBuckets = [
        RuleBreakers,
        UELRTTF,
        UCLRTTF,
        RecordBreakers,
        TOTGS,
        Headliners,
        WhatIf,
        WhatIfUpgrade,
        FUTBirthday,
        FutureStars,
      ]

      for (let i = 0; i < priceBuckets.length; i++) {
        let bucket = priceBuckets[i];
        let players = Object.keys(bucket);
        for (let j = 0; j < players.length; j++) {
          let player = players[j];
          prices[player + ' ' + bucket[player].type] = bucket[player];
        }
      }

      break;
    case 'moments':
      prices = MomentsPrices
      break;
    case '91+':
      prices = {}
      for (let i = 0; i < players.length; i++) {
        let playerName = players[i];
        let stats = PrimePrices[playerName];
        if (parseInt(stats.rating, 10) >= 91) {
          prices[playerName] = stats;
        }
      }
      break;
    case 'prime-or-moments':
      prices = combineTwoPrices(MomentsPrices, PrimePrices, ' Moments', ' Prime');
      break;
    case 'prime':
      prices = PrimePrices;
      break;
    case 'mid-or-prime':
      prices = combineTwoPrices(MidPrices, PrimePrices, ' Mid', ' Prime');
      break;
    case 'mid':
      prices = MidPrices;
      break;
    case 'base-or-mid':
      prices = combineTwoPrices(MidPrices, BasePrices, ' Mid', ' Base');
      break;
    case 'base':
      prices = BasePrices;
      break;
    default:
      prices = PrimePrices;
      break;
    }
    let playerNames = Object.keys(prices);
    for (let i = 0; i < playerNames.length; i++) {
      let player = playerNames[i];
      if (prices[player].price === 0) {
        prices[player].price = convert_str_to_number('15M')
      }
    }
    return prices;
}

export const selectedPlayersPercentage = (type, players) => {
  let prices = getPlayerPrices(type);
  let probability = calculateProbability(prices);
  let prob = 0;
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    if (type === '5x85') {
      prob += 1 - ((1 - probability[player.label]) ** 5);
    } else {
      prob += probability[player.label];
    }  
  }

  return numeral(prob).format('0[.]00a');
}

export const getMinMaxPrices = (type) => {
  let prices = getPlayerPrices(type);
  let players = Object.keys(prices);
  let min = 16000000;
  let max = 0;
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let curPrice = prices[player].price;
    if (curPrice > max) {
      max = curPrice;
    }

    if (curPrice < min) {
      min = curPrice;
    }
  }

  return {min, max}
}

export const probabilityWithinRange = (type, lowPrice, highPrice) => {
  let prices = getPlayerPrices(type);
  let players = Object.keys(prices);
  let probability = calculateProbability(prices);
  let prob = 0;
  let count = 0;

  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let price = prices[player].price
    if (price >= lowPrice && price <= highPrice) {
      prob += probability[player];
      count += 1;
    }
  }

  return {prob: numeral(prob).format('0[.]0'), count};
}